/** @file
 * Constantes para el componente loader con status progresivo creado en vue y js
 */

export const LOADER_SIZE = Object.freeze({
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
});

export const LOADER_SIZE_CLASS = Object.freeze({
  [LOADER_SIZE.SMALL]: 'loader--small',
  [LOADER_SIZE.MEDIUM]: 'loader--medium',
  [LOADER_SIZE.LARGE]: 'loader--large',
});

export const LOADER_DEFAULT_MESSAGES = Object.freeze([
  'Preparando todo para ti.',
  'Solo un momento más.',
  'Ya casi está listo, un instante más.',
  'Ajustando los últimos detalles.',
]);
