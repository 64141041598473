/** @file
 * Archivo de marcas de amplitud para workflow
 */
import onmount from 'onmount';

// Acción rápida "Otras solicitudes" desde el Portal del Colaborador
// Botón "Crear Solicitud" desde el index de solicitudes
onmount('.start-wf-amplitud', async function () {
  $(this).on('click', function () {
    const element = $(this);
    const importData = {
      'source': element.attr('data-source'),
    };
    sendAmplitudeTracking(element, 'Start_Request_Workflow', importData);
  });
});

// Botón "Solicitar" o "aprobar" dentro de un formulario de Workflow
// Al crear un proceso de Onboarding
onmount('.submit-wf-amplitud', async function () {
  $(this).on('click', function () {
    const element = $(this);
    const importData = {
      'source': element.attr('data-source'),
      'template_id': element.attr('data-template-id'),
      'template_type': element.attr('data-template-type'),
      'process_id': element.attr('data-process-id'),
      'total_operations': element.attr('data-total-operations'),
      'actual_operation': element.attr('data-actual-operation'),
      'automatic_notifications_enabled': element.attr('data-automatic-notifications-enabled'),
      'workflow_actions': element.attr('data-workflow-actions'),
      'template_name': element.attr('data-template-name'),
    };
    sendAmplitudeTracking(element, 'Request_Workflow', importData);
  });
});

// Botón "crear plantilla" en workflow/config
onmount('#create-template-workflow', async function () {
  $(this).on('click', function () {
    sendAmplitudeTracking($(this), 'Start_Create_Template_Workflow', {});
  });
});

// Dar crear a una plantilla de solicitudes
onmount('#save-template-workflow', async function () {
  $(this).on('click', function () {
    const element = $(this);
    const selectElement = $('#process_template_type').find(':selected');
    const nameElement = $('#process_template_title');
    const importData = {
      'template_type': selectElement.html(),
      'template_name': nameElement.val(),
    };
    if(selectElement.val() && nameElement.val()) {
      sendAmplitudeTracking(element, 'Create_Template_Workflow', importData);
    }
  });
});

function sendAmplitudeTracking(element, message, data) {
  element.trigger('amplitudeTracking', {
    message: message,
    data: data,
  });
}
