/** @file
 *
 * Permite agregarle comportamiento de seleccionar todos a una tabla a través de un checkbox de
 * "Seleccionar todos". Además, permite habilitar o deshabilitar botones de acciones relacionados a la tabla.
 * Además, Incluye un botón para seleccionar todos los elementos de la query actual al momento de hacer click
 * en el checkbox "Seleccionar todos".
 *
 * Requisitos:
 * - Una tabla con un checkbox de id con el prefijo `checkbox-select-all` y un checkbox por cada fila. Los checkboxes
 * - de cada fila deben tener un id único.
 * - Opcionalmente, botones de acciones con id `table-batch-action-<nombre>`.
 *
 * Funcionamiento:
 * - Cada vez que se hace click al checkbox `checkbox-select-all` se seleccionan el resto
 * de checkboxes pertenecientes a un tabla y se muestra un botón para seleccionar todos
 * los elementos de la query actual.
 * - También se habilitan o deshabilitan los botones de acciones según corresponda y se actualiza el
 * número de elementos seleccionados en la tabla.
 *
 * Ejemplo de uso:
 * <table>
 *   <thead>
 *     <tr>
 *       <th>
 *         <input id="checkbox-select-all" type="checkbox">
 *       </th>
 *   </thead>
 * </table>
 *
 */
import onmount from 'onmount';

/**
 * Función que deshabilita el selector de todas las páginas. Se oculta
 * el botón de "Seleccionar todos" y se cambia el estado de la variable
 * allElementsSelected a false.
 *
 * @param {TableHandler} tableHandler instancia del manejador de la tabla.
 *
 */
const hideAllElementsSelector = function (tableHandler) {
  const tableId = `#table_${tableHandler.resourceName}`;

  $(`#all-elements-selected-${tableHandler.resourceName}`).addClass('hidden');
  $(`#current-page-selected-${tableHandler.resourceName}`).addClass('hidden');
  $(`#table-row-all-selected_${tableHandler.resourceName}`).addClass('hidden');
  $(`th[id^=header][id$=${tableHandler.resourceName}]`).removeClass('hidden');
  $(`th[id=header-actions_${tableHandler.resourceName}]`).removeClass('hidden');
  $(`${tableId} .fiji-header__label`).removeClass('fiji-header__label--hidden');
  $(`${tableId} .fiji-header-actions__label`).removeClass('fiji-header-actions__label--hidden');
};

/**
 * Función que habilita el selector de todas las páginas. Se muestra
 * el botón de "Seleccionar todos"
 *
 * @param {TableHandler} tableHandler instancia del manejador de la tabla.
 *
 */
const showAllElementsSelected = function (tableHandler) {
  $(`#all-elements-selected_${tableHandler.resourceName}`).removeClass('hidden');
  $(`#current-page-selected_${tableHandler.resourceName}`).addClass('hidden');
};

/**
 * Función que muestra el mensaje de todos los elementos seleccionados Se muestra
 * el botón de "Limpiar selección"
 *
 * @param {TableHandler} tableHandler instancia del manejador de la tabla.
 *
 */
const showAllElementsSelector = function (tableHandler) {
  const tableId = `#table_${tableHandler.resourceName}`;

  $(`#all-elements-selected_${tableHandler.resourceName}`).addClass('hidden');
  $(`#current-page-selected_${tableHandler.resourceName}`).removeClass('hidden');
  $(`#table-row-all-selected_${tableHandler.resourceName}`).removeClass('hidden');
  $(`th[id^=header][id$=${tableHandler.resourceName}]`).addClass('hidden');
  $(`th[id=header-actions_${tableHandler.resourceName}]`).addClass('hidden');
  $(`${tableId} .fiji-header__label`).addClass('fiji-header__label--hidden');
  $(`${tableId} .fiji-header-actions__label`).addClass('fiji-header-actions__label--hidden');
};

/**
 * Método que optiene los ids de los checkboxes seleccionados.
 *
 * @param {TableHandler} tableHandler instancia del manejador de la tabla.
 *
 */
const getKeysChecked = function (tableHandler) {
  return Object.keys(tableHandler.checkboxesState)
    .filter((key) => tableHandler.checkboxesState[key]);
};

/**
 * Función que actualiza los elementos de la lista almacenada en
 * un formulario oculto identificado por el id 'fiji-saved-selects' los cuales
 * serán enviados al controlador al hacer click en la acción.
 *
 * @param {TableHandler} tableHandler instancia del manejador de la tabla.
 *
 */
const updateSavedSelects = function (tableHandler) {
  const elementId = `#saved-selects_${tableHandler.resourceName}`;
  if (tableHandler.allElementsSelected) {
    $(elementId).attr('value', 'all');
    return;
  }

  const idsChecked = getKeysChecked(tableHandler)
    .map((key) => key.replace('checkbox-', '').replace(`_${tableHandler.resourceName}`, ''));
  $(elementId).attr('value', idsChecked);
};

/**
 * Método para actualizar el estado del selector de todas las páginas.
 *
 * @param {TableHandler} tableHandler instancia del manejador de la tabla.
 * @param {Boolean} selected Si todos los elementos de la query están seleccionados.
 *
 */
const updateAllElementsSelected = function (tableHandler, selected) {
  tableHandler.allElementsSelected = selected;

  updateSavedSelects(tableHandler);
};

/**
 * Método que chequea si todos los checkboxes de la tabla están activos y muestra
 * el selector de todas las páginas en caso de estarlo.
 *
 * @param {TableHandler} tableHandler instancia del manejador de la tabla.
 * @param {Boolean} allChecked Si todos los elementos de la página actual están seleccionados.
 *
 */
const checkPageSelected = function (tableHandler, allChecked) {
  if (allChecked) {
    showAllElementsSelector(tableHandler);
    return;
  }

  updateAllElementsSelected(tableHandler, false);
  hideAllElementsSelector(tableHandler);
};

/**
 * Función que retorna el número de registros en la tabla.
 *
 * @param {String} resourceName - Nombre del recurso.
 * @returns {Number} - Número de registros en la tabla.
 */
function currentElementsCount(resourceName) {
  return Number($(`#total-elements_${resourceName}`).text());
}

/**
 * Función que limpia el estado de los checkboxes en la tabla.
 * Si la cardinalidad cambia, elimina los checkboxes que no están en la lista actual.
 *
 * @param {TableHandler} tableHandler - Instancia del manejador de la tabla.
 * @param {Array} checkboxesIds - Lista de IDs de los checkboxes actualmente en la tabla.
 */
function cleanCheckboxStates(tableHandler, checkboxesIds) {
  if ((currentElementsCount(tableHandler.resourceName)) !== tableHandler.totalElementsCount) {
    (Object.keys(tableHandler.checkboxesState).filter(n => !checkboxesIds.includes(n))).forEach(function (key) {
      delete tableHandler.checkboxesState[key];
    });
    tableHandler.totalElementsCount = currentElementsCount(tableHandler.resourceName);
  }
}

/**
 * Function que configura el estado actual de los checkboxes de la tabla en la vista.
 * Toma todos los elementos en la lista de checkboxes y los marca como seleccionados o no
 * dependiendo del estado guardado en `checkboxesState`.
 *
 * @param {TableHandler} tableHandler instancia del manejador de la tabla.
 *
 */
const setAlreadySelected = function (tableHandler) {
  let allChecked = !!tableHandler.checkboxes.length;
  const checkboxesIds = [];
  tableHandler.checkboxes.forEach(function (checkbox) {
    checkboxesIds.push(checkbox.id);
    checkbox.checked = tableHandler.checkboxesState[checkbox.id];
    allChecked = allChecked && tableHandler.checkboxesState[checkbox.id];
    $(checkbox).closest('tr').toggleClass('selected', tableHandler.checkboxesState[checkbox.id]);
  });

  cleanCheckboxStates(tableHandler, checkboxesIds);

  tableHandler.selectAllCheckbox.checked = allChecked;

  checkPageSelected(tableHandler, allChecked);
};

/**
 * Método que inicializa el estado interno de la lista de checkboxes. Por defecto todos
 * están deshabilitados.
 *
 * @param {TableHandler} tableHandler instancia del manejador de la tabla.
 *
 */
const initDefaultState = function (tableHandler) {
  tableHandler.checkboxes.forEach(function (checkbox) {
    if (checkbox.id in tableHandler.checkboxesState) {
      return;
    }

    tableHandler.checkboxesState[checkbox.id] = false;
  });

  tableHandler.allElementsSelected = false;
};

/**
 * Function que habilita o deshabilita los elementos de la lista de batch actions,
 * si al menos uno de los checkboxes está activo.
 *
 *  @param {TableHandler} tableHandler instancia del manejador de la tabla.
 *
 */
const setBatchActionsState = function (tableHandler) {
  const regexCounter = /.[[x|X]]/;

  // Se actualiza el formulario oculto con los elementos seleccionados.
  updateSavedSelects(tableHandler);

  // Se cuenta el numero de checkboxes activos o se toma el número total
  // de elementos de la query actual.
  const countChecked = tableHandler.allElementsSelected ?
    currentElementsCount(tableHandler.resourceName) :
    getKeysChecked(tableHandler).length;

  // Se actualiza el número de elementos seleccionados en el header de la tabla.
  document.getElementById(`selected-elements_${tableHandler.resourceName}`).innerText = countChecked;

  const actionsElements = $(`a[id^=batch-action-][id$=${tableHandler.resourceName}]`);
  // Se actualiza el número de elementos seleccionados en los botones de acciones.
  actionsElements.each(function () {
    const actionElement = $(this);
    const newElementCount = `(${countChecked})`;
    const disabled = actionElement.data('disabled');

    // En el primer render se reemplaza el texto por defecto [X] por el número de elementos
    // seleccionados. En los siguientes renders se reemplaza el número que fue reemplazado
    // anteriormente por el nuevo número de elementos seleccionados.
    actionElement.text(
      actionElement.text().replace(regexCounter, newElementCount).replace(/\((\d+)\)/g, newElementCount)
    );

    // Se actualiza los mensajes de confirmación de SweetAlert con la cantidad de elementos.
    const confirmMessage =  actionElement.attr('data-confirm-swal-title');
    if (confirmMessage) {
      actionElement.attr('data-confirm-swal-title',
        confirmMessage
          .replace(regexCounter, countChecked)
          .replace(/\d+/g, countChecked)
      );
    }
    const secondaryMessage =  actionElement.attr('data-confirm-swal-html');
    if (secondaryMessage) {
      actionElement.attr('data-confirm-swal-html',
        secondaryMessage
          .replace(regexCounter, countChecked)
          .replace(/\d+/g, countChecked)
      );
    }

    // Se habilitan o deshabilitan los botones de acciones según corresponda.
    if (disabled) {
      return;
    }

    if (countChecked === 0) {
      actionElement.addClass('disabled');
    }
    else {
      actionElement.removeClass('disabled');
    }
  });
};

/**
 * Método llamado en cada cambio de estado de un checkbox de la tabla. Actualiza el estado
 * de los elementos de la tabla y habilita o deshabilita los botones de acciones.
 *
 * @param {TableHandler} tableHandler instancia del manejador de la tabla.
 * @param {HTMLElement} checkbox Elemento que gatilla la llamada.
 *
 */
const onChangeTableCheckbox = function (tableHandler, checkbox) {
  const checkboxChecked = checkbox.checked;
  // Guardamos el estado actual del checkbox.
  tableHandler.checkboxesState[checkbox.id] = checkboxChecked;

  // Si al menos un checkbox está inactivo, se desactiva el botón "Seleccionar Todos".
  if (!checkboxChecked) {
    tableHandler.allElementsSelected = false;
  }

  // Chequeamos la lista de checkboxes para ver si todos están activos.
  let allChecked = true;
  tableHandler.checkboxes.forEach(function (currentCheckbox) {
    allChecked = allChecked && currentCheckbox.checked;
  });

  // Se actualiza el estado del checkbox que permite seleccionar toda la página.
  tableHandler.selectAllCheckbox.checked = allChecked;

  $(checkbox).closest('tr').toggleClass('selected', tableHandler.checkboxesState[checkbox.id]);

  // Se habilitan o deshabilitan los botones de acciones según corresponda.
  setBatchActionsState(tableHandler);

  // Checkeamos si se debe mostrar el selector de todas las páginas.
  checkPageSelected(tableHandler, allChecked);
};

/**
 * Método que retorna una función que será ejecutada al hacer click a los botones de "Seleccionar todos"
 * o "Limpiar Selección". Actualiza el estado de la variable allElementsSelected y cambia el botón
 * dependiendo del caso.
 *
 * @param {TableHandler} tableHandler instancia del manejador de la tabla.
 * @return {Function} función que será ejecutada al hacer click a los botones de "Seleccionar todos"
 *
 */
const toggleAllElementsSelected = function (tableHandler) {
  return (e) => {
    e.preventDefault();

    updateAllElementsSelected(tableHandler, !tableHandler.allElementsSelected);

    if (tableHandler.allElementsSelected) {
      showAllElementsSelected(tableHandler);
    }
    else {
      showAllElementsSelector(tableHandler);
    }

    setBatchActionsState(tableHandler);
  };
};

/**
 * Método llamado en cada cambio de estado del checkbox "Seleccionar Todos". Actualiza el estado
 * interno de la tabla y habilita o deshabilita los botones de acciones.
 *
 * @param {TableHandler} tableHandler instancia del manejador de la tabla.
 *
 */
const onChangeSelectAllCheckbox = function (tableHandler) {
  const allChecked = tableHandler.selectAllCheckbox.checked;
  // Modificamos los checkboxes visibles en la tabla y actualizamos el estado interno.
  tableHandler.checkboxes.forEach(function (checkbox) {
    checkbox.checked = allChecked;
    tableHandler.checkboxesState[checkbox.id] = allChecked;
    $(checkbox).closest('tr').toggleClass('selected', tableHandler.checkboxesState[checkbox.id]);
  });

  if (!allChecked) {
    tableHandler.allElementsSelected = false;
  }

  // Se habilitan o deshabilitan los botones de acciones según corresponda.
  setBatchActionsState(tableHandler);

  // Checkeamos si se debe mostrar el selector de todas las páginas.
  checkPageSelected(tableHandler, allChecked);
};

/**
  * Clase para manejar el estado de los checkboxes de una la tabla Fiji,
  * basado en el nombre del recurso. Como la tabla puede ser renderizada en varias páginas
  * solo debe existir una instancia por recurso.
  * @param {String} resourceName nombre del recurso.
  * @param {HTMLElement[]} checkboxes lista de elementos HTML que representan los checkboxes de la tabla.
  * @param {HTMLElement} selectAllCheckbox elemento HTML que representa el checkbox de "Seleccionar Todos".
  */
class TableHandler {
  static instances = {};

  checkboxesState = {};
  allElementsSelected = false;
  checkboxes;
  selectAllCheckbox;
  resourceName;
  totalElementsCount;

  constructor(resourceName, checkboxes, selectAllCheckbox, totalElementsCount) {
    this.resourceName = resourceName;
    this.checkboxes = checkboxes;
    this.selectAllCheckbox = selectAllCheckbox;
    this.totalElementsCount = totalElementsCount;
  }

  updateArgs(checkboxes, selectAllCheckbox) {
    this.checkboxes = checkboxes;
    this.selectAllCheckbox = selectAllCheckbox;
  }

  static getOrCreateInstance(resourceName, ...args) {
    if (resourceName in TableHandler.instances) {
      const tableHandle = TableHandler.instances[resourceName];
      tableHandle.updateArgs(...args);
      return tableHandle;
    }

    TableHandler.instances[resourceName] = new TableHandler(resourceName, ...args);
    return TableHandler.instances[resourceName];
  }

  static remove_instance(resourceName) {
    delete TableHandler.instances[resourceName];
  }
}

window.TableHandler = TableHandler;

const updateCheckboxState = function (selectAllCheckbox) {
  // Buscamos el id del recurso en el id del checkbox "Seleccionar Todos".
  const resourceName = selectAllCheckbox.id.replace('checkbox-select-all_', '');

  // Buscamos todos los checkboxes en la tabla que no sean el "Seleccionar Todos".
  const checkboxes = document
    .querySelectorAll(`[id^='checkbox-'][id$='_${resourceName}']:not(#checkbox-select-all_${resourceName})`);

  const totalElementsCount = currentElementsCount(resourceName);

  const tableHandler = TableHandler.getOrCreateInstance(
    resourceName,
    checkboxes,
    selectAllCheckbox,
    totalElementsCount
  );
  // Inicializamos el estado de los checkboxes si no fue hecho anteriormente.
  initDefaultState(tableHandler);

  // Al cambiar de página en la tabla se pierde el estado de los checkboxes, por lo que se debe
  // guardar y restaurar los checkboxes seleccionados.
  setAlreadySelected(tableHandler);

  // Se habilitan o deshabilitan los botones de acciones según corresponda.
  setBatchActionsState(tableHandler);

  // Se añade un listener al checkbox "Seleccionar Todos" para que al marcarlo se activen o desactiven todos
  // los checkboxes de la tabla.
  selectAllCheckbox.addEventListener('change', () => onChangeSelectAllCheckbox(tableHandler));

  // Se añade un listener a cada checkbox de la tabla para checkear el "Seleccionar Todos" si
  // todos los checkboxes de la tabla están activos.
  checkboxes.forEach(function (checkbox) {
    checkbox.addEventListener('change', () => onChangeTableCheckbox(tableHandler, checkbox));
  });
  $(`#select-all-matching_${resourceName}`).on('click', toggleAllElementsSelected(tableHandler));

  $(`#undo-all-selected_${resourceName}`).on('click', toggleAllElementsSelected(tableHandler));

};

const updateMultiselectCheckboxes = function () {
  $('[id^="checkbox-select-all"]').each(function () {
    updateCheckboxState($(this).get(0));
  });
};

onmount("[id^='checkbox-select-all']", function () {
  updateCheckboxState($(this).get(0));
});

export { updateMultiselectCheckboxes };
