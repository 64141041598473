/* eslint-disable buk/no-static-import */
/** @file
 * Helpers para el componente loader con status progresivo en vue y js
 */
import {
  LOADER_DEFAULT_MESSAGES,
  LOADER_SIZE,
  LOADER_SIZE_CLASS
} from '../constants/loader';

/**
 * Crea un elemento de loader con mensajes opcionales y un tamaño especificado.
 * @param {Object} options - Opciones para configurar el loader.
 * @param {'large' | 'medium' | 'small'} [options.size] - Tamaño del loader.
 * @param {string | string[] | boolean} [options.messages] - Mensaje o lista de mensajes que se mostrarán en el loader.
 * @returns {string} - Código HTML del loader generado.
 */
export function createLoader({ size = LOADER_SIZE.LARGE, messages } = {}) {
  if(!isValidLoaderSize(size)) {
    throw new Error(`El tipo de loader "${size}" no es valido.`);
  }

  const loaderMessages = getLoaderMessages(messages);
  const sizeClass = getLoaderSizeClass(size);

  return `<div class="loader container-loader ${sizeClass}">
    <div class="loader__content">
      ${getLoaderAnimation()}
      ${
  loaderMessages.length > 0
    ? `<p id="${getRandomId()}" class="loader-messages">
            ${loaderMessages
    .map(
      (message, index) =>
        `<span class="loader-messages__item ${
          index === 0 ? 'loader-messages__item--visible' : ''
        }">${message}</span>`
    )
    .join('\n')}
          </p>`
    : ''
}
    </div>
  </div>`;
}

/**
 * Obtiene una lista de mensajes para el loader.
 * @param {boolean|string|string[]} messages - Mensajes personalizados o indicador para usar los mensajes predeterminados.
 * - Si es `true`, retorna los mensajes predeterminados.
 * - Si es una cadena, la convierte en un array con un solo mensaje.
 * - Si es un array, lo retorna tal cual.
 * - En cualquier otro caso, retorna un array vacío.
 * @returns {string[]} - Lista de mensajes a mostrar en el loader.
 */
function getLoaderMessages(messages) {
  if (messages === true) {
    return LOADER_DEFAULT_MESSAGES;
  }
  if (typeof messages === 'string') {
    return [messages];
  }
  return Array.isArray(messages) ? messages : [];
}

/**
 * Obtiene las clases CSS correspondiente al tamaño del loader.
 * @param {string} size - Tamaño del loader. Debe ser una clave válida de `LOADER_SIZE_CLASS`.
 * @returns {string} - Clases CSS asociadas al tamaño especificado.
 */
export function getLoaderSizeClass(size) {
  if (!LOADER_SIZE_CLASS[size]) {
    // eslint-disable-next-line no-console
    console.warn(`[Loader] Tamaño inválido: "${size}". Se usará "large" por defecto.`);
  }

  return LOADER_SIZE_CLASS[size]
    ? LOADER_SIZE_CLASS[size]
    : LOADER_SIZE_CLASS[LOADER_SIZE.LARGE];
}

/**
 * Genera un identificador aleatorio en formato de cadena alfanumérica.
 * @returns {string} - Un ID aleatorio.
 */
export function getRandomId() {
  return Math.random()
    .toString(36)
    .substring(2);
}

/**
 * Verifica si el tamaño proporcionado es válido para el loader.
 * @param {string} size - Tamaño a validar.
 * @returns {boolean} - `true` si el tamaño es válido.
 */
export function isValidLoaderSize(size) {
  return Object.values(LOADER_SIZE).includes(size);
}

/**
 * Genera el código SVG de la animación del loader.
 * @returns {string} - Código HTML que representa la animación del loader en formato SVG.
 */
export function getLoaderAnimation() {
  // Se genera un ID aleatorio para evitar conflictos en los elementos `clipPath` y `mask`
  const randomId = getRandomId();

  return `<svg
  xmlns="http://www.w3.org/2000/svg" height="108px" width="108px" viewBox="0 0 128 128"
  class="buk-loader loader__animation"
>
  <defs>
    <clipPath id="loader-eyes-${randomId}">
      <circle transform="rotate(-40,64,64) translate(0,-56)" r="8" cy="64" cx="64" class="loader__eye1" />
      <circle transform="rotate(40,64,64) translate(0,-56)" r="8" cy="64" cx="64" class="loader__eye2" />
    </clipPath>

    <mask id="loader-mask-${randomId}">
      <rect fill="url(#loader-grad)" height="128" width="128" y="0" x="0" />
    </mask>
  </defs>
  <g stroke-dasharray="175.93 351.86" stroke-width="12" stroke-linecap="round">
    <g>
      <rect clip-path="url(#loader-eyes-${randomId})" height="64" width="128" fill="hsl(225,54%,43%)" />
      <g stroke="hsl(225,54%,43%)" fill="none">
        <circle transform="rotate(180,64,64)" r="56" cy="64" cx="64" class="loader__mouth1" />
        <circle transform="rotate(0,64,64)" r="56" cy="64" cx="64" class="loader__mouth2" />
      </g>
    </g>
    <g mask="url(#loader-mask-${randomId})">
      <rect clip-path="url(#loader-eyes-${randomId})" height="64" width="128" fill="hsl(223,90%,50%)" />
      <g stroke="hsl(223,90%,50%)" fill="none">
        <circle transform="rotate(180,64,64)" r="56" cy="64" cx="64" class="loader__mouth1" />
        <circle transform="rotate(0,64,64)" r="56" cy="64" cx="64" class="loader__mouth2" />
      </g>
    </g>
  </g>
</svg>`;
}
