/** @file
 * behaviors para mostrar/ocultar columnas en fiji.
 */
import onmount from 'onmount';

onmount('.fiji-header', function () {
  // Seleccionar el elemento `.fiji_actions`
  const headerActions = this.querySelector('.fiji-header-actions');
  if (!headerActions) return;

  // Detectar el overflow horizontal de `.fiji-header`
  const handleOverflow = () => {
    const hasOverflow = hasHorizontalScroll(this); // `this` es el elemento `.fiji-header`
    toggleActiveClass(headerActions, hasOverflow);
  };

  // Observar cambios en el tamaño del contenedor `.fiji-header`
  const resizeObserver = new ResizeObserver(handleOverflow);
  resizeObserver.observe(this);

  // Aplicar estado inicial al montar el componente
  handleOverflow();

  // Desconectar el observador al desmontar
  return () => resizeObserver.disconnect();
});

/** Verifica si el elemento tiene overflow horizontal */
function hasHorizontalScroll(element) {
  return element.scrollWidth > element.clientWidth;
}

/** Agrega o remueve la clase activa según el estado de overflow */
function toggleActiveClass(element, isActive) {
  if (isActive) {
    element.classList.add('fiji_actions--active');
  }
  else {
    element.classList.remove('fiji_actions--active');
  }
}

// Esta variable solo se aplica cuando el dropdown de visibilidad de columnas está dentro de un contenedor con overflow.
// por ejemplo en una tabla con pocos registros.
/**
 * Obtiene el estado inicial de las opciones de visibilidad de columnas.
 * @param {jQuery} $dropdown - Dropdown de visibilidad de columnas.
 * @returns {Array<boolean>} - Arreglo de booleanos que indica si cada columna está visible o no.
 * */
function getInitialStateOptions($dropdown) {
  const initialStateOtions = $dropdown.find('input[type="checkbox"]').map(function () {
    return this.checked;
  }).get();
  return initialStateOtions;
}

function stopPropagationMenu($dropdown) {
  $dropdown.on('click mouseenter', '.dropdown-menu', function (e) {
    $(e.currentTarget).tooltip('hide');
    e.stopPropagation();
  });
}

/**
 * Evita que contenedor table-responsive overflow auto corte el dropdown de visibilidad de columnas.
 * @param {jQuery} $dropdown - Dropdown de visibilidad de columnas.
 * @param {jQuery} $fijiBody - Contenedor de la tabla.
 * @param {jQuery} $submitButton - Botón submit del formulario
 * */
function handleOverflowTable($dropdown) {
  const dropdownMenu = $dropdown.children('.dropdown-menu');
  const closestForm = $dropdown.closest('form');
  const submitButton = closestForm.find('#column-visibility-dropdown__submit');
  const personalizedResourceId = closestForm.attr('data-body-id');
  const $fijiBody = $(`#${personalizedResourceId}.fiji-body`);
  $dropdown.on('show.bs.dropdown', function () {
    const dropdownOffset = $dropdown.offset();
    const dropdownMenuHeight = dropdownMenu.outerHeight();
    const headerSticky = $dropdown.closest('.fiji-header');
    const tableHeight = $dropdown.closest(headerSticky).outerHeight();

    const tableBottom = $dropdown.closest(headerSticky).offset().top + tableHeight;

    const dropdownBottom = dropdownOffset.top + $dropdown.outerHeight() + dropdownMenuHeight;
    if (dropdownBottom > tableBottom) {
      // Mover el dropdown fuera del contenedor de la tabla para evitar recortes
      dropdownMenu.addClass('dropdown-menu--overflow');
      dropdownMenu.addClass('sticky-header');
      dropdownMenu.appendTo($fijiBody);
      stopPropagationMenu($fijiBody);
      // Ajustar la posición del menú para que coincida con el botón de apertura
      dropdownMenu.css({
        position: 'absolute',
        top: dropdownOffset.top + $dropdown.outerHeight(),
        left: dropdownOffset.left,
        display: 'block',
      });
    }
    // mover el dropdown al contenedor original antes de enviar el formulario
    submitButton.off('click').on('click', function (event) {
      dropdownMenu.appendTo($dropdown);
      dropdownMenu.css({
        display: 'none',
      });
      event.preventDefault();
      $dropdown.attr('disabled', true);
      closestForm.submit();
    });
  });

  // Restaurar el dropdown dentro de su contenedor original para mantener el flujo DOM
  $dropdown.on('hide.bs.dropdown', function () {
    dropdownMenu.appendTo($dropdown);
    dropdownMenu.css({
      display: 'none',
    });
  });
}
/**
 * Habilita el botón de aplicar cambios si el usuario cambia la visibilidad de las columnas.
 * @param {jQuery} $dropdown - Dropdown de visibilidad de columnas.
 * @param {jQuery} $applyButton - Botón submit del dropdown.
 * @param {Array<boolean>} initialStateOptions - Estado inicial de las opciones de visibilidad de columnas.
 * @param {jQuery} optionsContainer - Contenedor de dropdown de opciones que cambia si existe overflow.
 * @returns {void}
 * */
function userChangeOptionVisibility($dropdown) {
  const applyButton = $dropdown.find('#column-visibility-dropdown__submit');
  const initialStateOptions = getInitialStateOptions($dropdown);
  const form = applyButton.closest('form');
  const optionsContainer = $dropdown.find('.dropdown-menu');
  optionsContainer.on('change', 'input[type="checkbox"]', function () {
    const currentStateOptions = getInitialStateOptions(optionsContainer);
    const isDifferent = initialStateOptions.some((value, index) => value !== currentStateOptions[index]);
    applyButton.prop('disabled', !isDifferent);
  });

  form.on('submit', function () {
    userChangeOptionVisibility($dropdown);
    applyButton.prop('disabled', true);
    $dropdown.find('button[data-toggle="dropdown"]').attr('disabled', true);
    $dropdown.click();
  });
}

/**
 * Función para trackeo de uso de funcionalidad en amplitude:
 * Para cada click del usuario en los checkbox agrega el valor al arreglo correspondiente.
 * @param {jQuery} $dropdown - Dropdown de visibilidad de columnas.
 * @returns {void}
 * */

function trackingAmplitudeEvent($dropdown) {
  const form = $dropdown.closest('form');
  const showColumns = [], hideColumns = [];

  const updateColumns = (label, addToShow) => {
    const [addList, removeList] = addToShow ? [showColumns, hideColumns] : [hideColumns, showColumns];
    const removeIndex = removeList.indexOf(label);
    if (removeIndex !== -1) removeList.splice(removeIndex, 1);
    if (!addList.includes(label)) addList.push(label);
  };

  const optionsContainer = $dropdown.find('.dropdown-menu');

  optionsContainer.on('change', 'input[type="checkbox"]', function () {
    const label = $(this).closest('label').text().trim();
    updateColumns(label, $(this).prop('checked'));
  });

  form.off('submit.amplitudeTracking');

  form.on('submit.amplitudeTracking', function (event) {
    event.preventDefault();
    form.trigger('amplitudeTracking', {
      message: 'Hide_Show_Column',
      data: {
        'table_source': form.attr('amplitude-table-source'),
        'show_columns': showColumns,
        'hide_columns': hideColumns,
      },
    });
  });
}

/**
 * Deshabilita el dropdown de visibilidad de columnas si se está realizando una petición intercooler.
 *
 * @param {jQuery} $dropdown - Dropdown de visibilidad de columnas.
 * @returns {void}
 * */
function disableOnIntercoolerInFlight($dropdown) {
  const closestFormInFlight = $dropdown.closest('form').hasClass('ic-request-in-flight');
  if (closestFormInFlight) {
    $dropdown.find('button[data-toggle="dropdown"]').attr('disabled', true);
  }
  else {
    $dropdown.find('button[data-toggle="dropdown"]').removeAttr('disabled');
  }
}

/**
 * Inicializa el dropdown de visibilidad de columnas.
 * @param {jQuery} $dropdown - Dropdown de visibilidad de columnas.
 * @returns {void}
 */
function triggerColumnVisibilityDropdown() {
  const dropdown = $('.column-visibility');
  disableOnIntercoolerInFlight(dropdown);
}

function removeTooltipClickDropdown($dropdown) {
  $dropdown.closest('span[data-toggle="tooltip"]').on('click', function () {
    $(this).tooltip('hide');
  });
}

onmount('.column-visibility', function () {
  const $dropdown = $(this);
  stopPropagationMenu($dropdown);
  handleOverflowTable($dropdown);
  trackingAmplitudeEvent($dropdown);
  userChangeOptionVisibility($dropdown);
  removeTooltipClickDropdown($dropdown);
});
window.triggerColumnVisibilityDropdown = triggerColumnVisibilityDropdown;
