/* eslint-disable brace-style */
/**
 * @file
 * Permite establecer o quitar el atributo `required` de un input dinámicamente basado en el valor de otro input.
 *
 * - El comportamiento está basado en los atributos `data-required-id` y `data-required-value`.
 *   - `data-required-id`: El ID del input objetivo que se modificará.
 *   - `data-required-value`: El valor que activa o desactiva el atributo `required`.
 *
 * Uso:
 * 1. Agrega `data-required-id` y `data-required-value` a cualquier campo de input o select. Estos atributos
 *    deben contener el ID del input objetivo y el valor esperado para activar el `required`.
 * 2. El campo asociado se volverá requerido o no basado en el valor definido en `data-required-value`.
 *
 * Ejemplo de HTML:
 * <input type='checkbox' id='with_settlement' data-required-id='wage' data-required-value='true' />
 * <input type='number' id='wage' />
 */
import onmount from 'onmount';

onmount('input[data-required-id], select[data-required-id]', function () {
  const field = this;
  const targetFieldId = field.getAttribute('data-required-id');
  const requiredValue = field.getAttribute('data-required-value');
  const requiredStep = field.getAttribute('data-required-step');
  const targetField = document.getElementById(targetFieldId);

  if (targetField) {
    // Buscar el label asociado al campo objetivo
    const targetLabel = document.querySelector(`label[for="${targetFieldId}"]`);

    // Método que actualiza el atributo `required` del input objetivo y la clase `required` en el label
    const updateRequiredStatus = () => {
      let isRequired = false;

      // Para checkbox, comparamos con su estado (true o false)
      // Para otros tipos de input, comparamos con su valor
      if (field.type === 'checkbox') {
        isRequired = field.checked === (requiredValue === 'true');
        // eslint-disable-next-line brace-style
      } else {
        isRequired = field.value === requiredValue;
      }

      // Establecemos o removemos el atributo `required` en el campo objetivo
      if (isRequired) {
        targetField.setAttribute('required', 'required');
        targetField.classList.add('required');
        if (targetLabel) {
          targetLabel.classList.add('required');
          if (!targetLabel.textContent.includes('*')) {
            targetLabel.textContent += ' *';
          }
        }
        if (requiredStep) {
          targetField.setAttribute('step', requiredStep);
        }
      } else {
        targetField.removeAttribute('required');
        targetField.classList.remove('required');
        if (targetLabel) {
          targetLabel.classList.remove('required');
          // Remover el `*` del final si existe
          targetLabel.textContent = targetLabel.textContent.replace(/\s?\*$/, '');
          if (requiredStep && targetField.hasAttribute('step')) {
            targetField.removeAttribute('step');
          }
        }
      }
    };

    // Llamada inicial para establecer el estado correcto al cargar la página
    updateRequiredStatus();

    // Escuchar eventos para cambios dinámicos
    field.addEventListener('change', updateRequiredStatus);
    field.addEventListener('input', updateRequiredStatus);
  }
});
